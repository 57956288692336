export const initialValues = {
  _id: null,
  questionRu: "",
  questionUzk: "",
  questionUz: "",
  answerRu: "",
  answerUzk: "",
  answerUz: "",
  for: "",
};
