import instance from "../app/axios";
import IService from "./../types/Content";

const getAll = () => {
  return instance.get<Array<IService>>("/services");
};

const get = (slug: any) => {
  return instance.get<IService>(`/services/${slug}`);
};

const create = (data: IService) => {
  return instance.post<IService>("/services", data);
};

const update = (id: any, data: IService) => {
  return instance.patch<any>(`/services/${id}`, data);
};

const remove = (id: any) => {
  return instance.delete<any>(`/services/${id}`);
};

const ContentService = {
  getAll,
  get,
  create,
  update,
  remove,
};

export default ContentService;
