import { useEffect } from "react";
import { useAppDispatch } from "../../app/hooks";
import bg from "../../assets/images/progress-hd.png";
import { useSignInUserMutation } from "../../redux/api/authApi";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { setUser } from "../../redux/authSlice";
import { toast } from "react-toastify";

const initialValues = {
  email: "",
  password: "",
};
const Login = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const [signInUser, { data, isLoading, error, isError }] =
    useSignInUserMutation();

  useEffect(() => {
    if (isError) {
      toast.error((error as any).data?.error);
    }

    if (data?.token) {
      dispatch(
        setUser({
          token: data?.token,
          name: data?.user?.name,
          email: data?.user?.email,
          role: data?.user?.role,
        })
      );
      toast.success("Login successful");
      navigate("/");
      window.location.reload();
      localStorage.setItem("token", data.token);
      localStorage.setItem("user", JSON.stringify(data.user));
    }
  }, [isError, navigate, error, dispatch, data?.user, data?.token]);

  const formik = useFormik({
    initialValues,
    onSubmit: async (values: any) => {
      signInUser({ ...values });
    },
  });

  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain"
        style={{
          backgroundImage: `url(${bg})`,
        }}
      >
        <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
          <div className="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
            <form className="form w-100" onSubmit={formik.handleSubmit}>
              <div className="text-center mb-10">
                <h1 className="text-dark mb-3">Sign In to Admin panel</h1>
              </div>
              <div className="fv-row mb-10">
                <div className="d-flex flex-stack mb-2">
                  <label className="form-label fw-bolder text-dark fs-6 mb-0">
                    Email
                  </label>
                </div>
                <input
                  className="form-control form-control-lg form-control-solid"
                  {...formik.getFieldProps("email")}
                  type="text"
                  name="email"
                  autoComplete="off"
                />
              </div>
              <div className="fv-row mb-10">
                <div className="d-flex flex-stack mb-2">
                  <label className="form-label fw-bolder text-dark fs-6 mb-0">
                    Password
                  </label>
                  {/* <a href="authentication/flows/basic/password-reset.html" className="link-primary fs-6 fw-bolder">Forgot Password ?</a> */}
                </div>
                <input
                  className="form-control form-control-lg form-control-solid"
                  {...formik.getFieldProps("password")}
                  type="password"
                  name="password"
                  autoComplete="off"
                />
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  id="kt_sign_in_submit"
                  className="btn btn-lg btn-primary w-100 mb-5"
                >
                  <span className="indicator-label">Continue</span>
                  <span
                    className={`indicator-${isLoading ? "label" : "progress"}`}
                  >
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
