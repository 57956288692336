import Faq from "../Faq";
import CreateService from "../Service/CreateService";
import Service from "./../Service/index";
import UpdateService from "./../Service/UpdateService";
import CreateFaq from "./../Faq/CreateFaq";
import UpdateFaq from "./../Faq/UpdateFaq";
export const routes = [
  {
    path: "/",
    element: <Service />,
  },
  {
    path: "/faq",
    element: <Faq />,
  },
  {
    path: "/create/service",
    element: <CreateService />,
  },
  {
    path: "/update/service/:slug",
    element: <UpdateService />,
  },
  {
    path: "/create/faq",
    element: <CreateFaq />,
  },
  {
    path: "/update/faq/:id",
    element: <UpdateFaq />,
  },
];
