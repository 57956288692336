import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import IFaq from "../../types/FaqType";
import FaqService from "../../services/FaqService";
import { initialValues } from "./InitialValues";

const UpdateFaq = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [card, setCard] = useState<IFaq>(initialValues);
  const [loading, setLoading] = useState<Boolean>(false);

  const getFaq = (id: string) => {
    FaqService.get(id)
      .then((response: any) => {
        setCard(response.data.data.faq);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (id) getFaq(id);
  }, [id]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCard({ ...card, [name]: value });
  };

  const updateCard = () => {
    setLoading(true);
    FaqService.update(card?._id, card)
      .then((response: any) => {
        if (response.data.status === "success") {
          setLoading(false);
          toast.success("Updated successfully");
          navigate("/faq");
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  useEffect(() => {}, [navigate]);

  return (
    <div>
      <h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">
        Update faq
      </h1>
      <div className="modal-body py-10 px-lg-17">
        <div className="scroll-y me-n7 pe-7">
          <div className="fv-row mb-7 fv-plugins-icon-container">
            <label className="required fs-6 fw-bold mb-2">Question Ru</label>
            <input
              type="text"
              className="form-control form-control-solid"
              placeholder=""
              name="questionRu"
              value={card.questionRu}
              onChange={handleInputChange}
            />
            <div className="fv-plugins-message-container invalid-feedback"></div>
          </div>
          <div className="fv-row mb-7 fv-plugins-icon-container">
            <label className="required fs-6 fw-bold mb-2">Question Uzk</label>
            <input
              type="text"
              className="form-control form-control-solid"
              placeholder=""
              name="titleUzk"
              value={card.questionUzk}
              onChange={handleInputChange}
            />
            <div className="fv-plugins-message-container invalid-feedback"></div>
          </div>
          <div className="row g-9 mb-7">
            <div className="fv-row fv-plugins-icon-container">
              <label className="required fs-6 fw-bold mb-2">Question Uz</label>
              <input
                className="form-control form-control-solid"
                placeholder=""
                name="questionUz"
                onChange={handleInputChange}
                value={card.questionUz}
              />
              <div className="fv-plugins-message-container invalid-feedback"></div>
            </div>
          </div>
          <div className="row g-9 mb-7">
            <div className="fv-row fv-plugins-icon-container">
              <label className="required fs-6 fw-bold mb-2">Answer Ru</label>
              <input
                className="form-control form-control-solid"
                placeholder=""
                name="answerRu"
                onChange={handleInputChange}
                value={card.answerRu}
              />
              <div className="fv-plugins-message-container invalid-feedback"></div>
            </div>
          </div>
          <div className="row g-9 mb-7">
            <div className="fv-row fv-plugins-icon-container">
              <label className="required fs-6 fw-bold mb-2">Answer Uzk</label>
              <input
                className="form-control form-control-solid"
                placeholder=""
                name="answerUzk"
                onChange={handleInputChange}
                value={card.answerUzk}
              />
              <div className="fv-plugins-message-container invalid-feedback"></div>
            </div>
          </div>
          <div className="row g-9 mb-7">
            <div className="fv-row fv-plugins-icon-container">
              <label className="required fs-6 fw-bold mb-2">Answer Uz</label>
              <input
                className="form-control form-control-solid"
                placeholder=""
                name="answerUz"
                onChange={handleInputChange}
                value={card.answerUz}
              />
              <div className="fv-plugins-message-container invalid-feedback"></div>
            </div>
          </div>
          <div className="fv-row mb-7 fv-plugins-icon-container">
            <label className="required fs-6 fw-bold mb-2">For</label>
            <input
              type="text"
              className="form-control form-control-solid"
              placeholder=""
              name="for"
              value={card.for}
              onChange={handleInputChange}
            />
            <div className="fv-plugins-message-container invalid-feedback"></div>
          </div>
          <div className="row justify-content-end">
            <button
              className="btn btn-primary d-flex ml-auto"
              style={{
                width: "fit-content",
              }}
              onClick={updateCard}
            >
              Submit
              <span className={`indicator-${loading ? "label" : "progress"}`}>
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateFaq;
