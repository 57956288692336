import instance from "../app/axios";
import IFaq from "../types/FaqType";

const getAll = () => {
  return instance.get<Array<IFaq>>("/faqs");
};

const get = (id: any) => {
  return instance.get<IFaq>(`/faqs/${id}`);
};

const create = (data: IFaq) => {
  return instance.post<IFaq>("/faqs", data);
};

const update = (id: any, data: IFaq) => {
  return instance.patch<any>(`/faqs/${id}`, data);
};

const remove = (id: any) => {
  return instance.delete<any>(`/faqs/${id}`);
};

const FaqService = {
  getAll,
  get,
  create,
  update,
  remove,
};

export default FaqService;
