import { Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../app/hooks";

export const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();

  const { token, role } = useAppSelector((state) => state.auth);

  return token && role === "admin" ? (
    children
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
};
