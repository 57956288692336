import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ContentService from "../../services/ContentService";
import IService from "./../../types/Content";
import SimpleMDE from "react-simplemde-editor";
import { initialValues } from "./InitialValues";

const CreateService = () => {
  const navigate = useNavigate();

  const [card, setCard] = useState<IService>(initialValues);
  const [loading, setLoading] = useState<Boolean>(false);

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement> | string,
    name: string
  ) => {
    console.log(event);
    setCard({ ...card, [name]: event });
  };

  const createCard = () => {
    setLoading(true);
    var data = {
      titleRu: card.titleRu,
      titleUzk: card.titleUzk,
      titleUz: card.titleUz,
      contentRu: card.contentRu,
      contentUzk: card.contentUzk,
      contentUz: card.contentUz,
      coverImage: card.coverImage,
      slug: card.slug,
      for: card.for,
    };

    console.log(data);

    ContentService.create(data)
      .then((response: any) => {
        setCard({
          _id: response.data.data.service._id,
          titleRu: response.data.data.service.titleRu,
          titleUzk: response.data.data.service.titleUzk,
          titleUz: response.data.data.service.titleUz,
          contentRu: response.data.data.service.contentRu,
          contentUzk: response.data.data.service.contentUzk,
          contentUz: response.data.data.service.contentUz,
          coverImage: response.data.data.service.coverImage,
          slug: response.data.data.service.slug,
          for: response.data.data.service.slug,
        });
        if (response.data.status === "success") {
          setLoading(false);
          navigate("/");
          toast("Created successfully");
        }
      })
      .catch((e: any) => {
        setLoading(false);
        toast.error(e.message, {
          position: "top-right",
          autoClose: 5000,
        });
      });
  };

  useEffect(() => {}, [navigate]);

  return (
    <div>
      <h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">
        Create service
      </h1>
      <div className="modal-body py-10 px-lg-17">
        <div className="scroll-y me-n7 pe-7">
          <div className="fv-row mb-7 fv-plugins-icon-container">
            <label className="required fs-6 fw-bold mb-2">Title Ru</label>
            <input
              type="text"
              className="form-control form-control-solid"
              placeholder=""
              name="titleRu"
              value={card.titleRu}
              onChange={(e) => handleInputChange(e.target.value, "titleRu")}
            />
            <div className="fv-plugins-message-container invalid-feedback"></div>
          </div>
          <div className="fv-row mb-7 fv-plugins-icon-container">
            <label className="required fs-6 fw-bold mb-2">Title Uzk</label>
            <input
              type="text"
              className="form-control form-control-solid"
              placeholder=""
              name="titleUzk"
              value={card.titleUzk}
              onChange={(e) => handleInputChange(e.target.value, "titleUzk")}
            />
            <div className="fv-plugins-message-container invalid-feedback"></div>
          </div>
          <div className="row g-9 mb-7">
            <div className="fv-row fv-plugins-icon-container">
              <label className="required fs-6 fw-bold mb-2">Title Uz</label>
              <input
                className="form-control form-control-solid"
                placeholder=""
                name="titleUz"
                onChange={(e) => handleInputChange(e.target.value, "titleUz")}
                value={card.titleUz}
              />
              <div className="fv-plugins-message-container invalid-feedback"></div>
            </div>
          </div>
          <div className="row g-9 mb-7">
            <div className="col-md-6 fv-row fv-plugins-icon-container">
              <label className="required fs-6 fw-bold mb-2">Slug</label>
              <input
                className="form-control form-control-solid"
                placeholder=""
                name="slug"
                onChange={(e) => handleInputChange(e.target.value, "slug")}
                value={card.slug}
              />
              <div className="fv-plugins-message-container invalid-feedback"></div>
            </div>
            <div className="col-md-6 fv-row fv-plugins-icon-container">
              <label className="required fs-6 fw-bold mb-2">Cover Image</label>
              <input
                className="form-control form-control-solid"
                placeholder=""
                name="coverImage"
                onChange={(e) =>
                  handleInputChange(e.target.value, "coverImage")
                }
                value={card.coverImage}
              />
              <img src={card.coverImage} alt="coverImage" width="100%" />
              <div className="fv-plugins-message-container invalid-feedback"></div>
            </div>
          </div>
          <div className="row g-9 mb-7">
            <div className="fv-row fv-plugins-icon-container">
              <label className="required fs-6 fw-bold mb-2">Content Ru</label>
              <SimpleMDE
                value={card.contentRu}
                onChange={(e) => handleInputChange(e, "contentRu")}
              />
              <div className="fv-plugins-message-container invalid-feedback"></div>
            </div>
          </div>
          <div className="row g-9 mb-7">
            <div className="fv-row fv-plugins-icon-container">
              <label className="required fs-6 fw-bold mb-2">Content Uzk</label>
              <SimpleMDE
                value={card.contentUzk}
                onChange={(e) => handleInputChange(e, "contentUzk")}
              />
              <div className="fv-plugins-message-container invalid-feedback"></div>
            </div>
          </div>
          <div className="row g-9 mb-7">
            <div className="fv-row fv-plugins-icon-container">
              <label className="required fs-6 fw-bold mb-2">Content Uz</label>
              <SimpleMDE
                value={card.contentUz}
                onChange={(e) => handleInputChange(e, "contentUz")}
              />
              <div className="fv-plugins-message-container invalid-feedback"></div>
            </div>
          </div>
          <div className="row g-9 mb-7">
            <div className="fv-row fv-plugins-icon-container">
              <label className="required fs-6 fw-bold mb-2">For</label>
              <input
                className="form-control form-control-solid"
                placeholder=""
                name="for"
                onChange={(e) => handleInputChange(e.target.value, "for")}
                value={card.for}
              />
              <div className="fv-plugins-message-container invalid-feedback"></div>
            </div>
          </div>
          <div className="row justify-content-end">
            <button
              className="btn btn-primary d-flex ml-auto"
              style={{
                width: "fit-content",
              }}
              onClick={createCard}
            >
              Submit
              <span className={`indicator-${loading ? "label" : "progress"}`}>
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateService;
