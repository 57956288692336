import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import instance from "../../app/axios";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: instance.defaults.baseURL,
  }),
  endpoints: (builder) => ({
    signInUser: builder.mutation({
      query: (body: { email: string; password: string }) => {
        return {
          url: "/auth/sign-in",
          method: "post",
          body,
        };
      },
    }),
  }),
});

export const { useSignInUserMutation } = authApi;
