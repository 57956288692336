import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ContentService from "../../services/ContentService";
import IService from "./../../types/Content";

const Service = () => {
  const [data, setData] = useState<Array<IService>>([]);
  const [currentData, setCurrentData] = useState<IService | null>(null);
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const [loading, setLoading] = useState<boolean>(false);

  const getData = () => {
    setLoading(true);
    ContentService.getAll()
      .then((response: any) => {
        setData(response.data.data.services);
        setLoading(false);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const setActiveData = (card: IService, index: number) => {
    setCurrentData(card);
    setCurrentIndex(index);
  };

  const deleteCard = (id: any) => {
    setLoading(true);
    ContentService.remove(id)
      .then((response: any) => {
        let newData = data.filter((d) => d._id !== id);
        setData(newData);
        if (response.status === 200) {
          setLoading(false);
          toast("Deleted successfully");
        }
      })
      .catch((e: Error) => {
        console.log(e.message, "message");
      });
  };

  return (
    <div className="list row">
      <div className="col-md-8">
        <div className="d-flex justify-content-between mt-5">
          <h3>Services List</h3>
          <Link to="/create/service" className="btn btn-sm btn-primary">
            Create
          </Link>
        </div>
        {!loading ? (
          <ul className="card-body list-group">
            {data &&
              data?.map((res, index) => (
                <li
                  className={
                    "d-flex align-items-center justify-content-between list-group-item " +
                    (index === currentIndex ? "active" : "")
                  }
                  onClick={() => setActiveData(res, index)}
                  key={index}
                >
                  {res.titleRu}
                  <div>
                    <div className="btn btn-light-secondary btn-sm ml-5">
                      {res?.for.toUpperCase()}
                    </div>
                    <Link
                      to={`/update/service/${res.slug}`}
                      className="btn btn-light-primary btn-sm ml-5"
                    >
                      Edit
                    </Link>
                    <button
                      onClick={() => deleteCard(res._id)}
                      className="btn btn-light-danger btn-sm ml-5"
                    >
                      delete
                    </button>
                  </div>
                </li>
              ))}
          </ul>
        ) : (
          <div className="spinner-border" role="status">
            <span className="sr-only"></span>
          </div>
        )}
      </div>
      <div className="col-md-4">
        {currentData ? (
          <div>
            <h4>Service</h4>
            <div>
              <img src={currentData.coverImage} alt="coverImage" width="100%" />
            </div>
            <div>
              <label>
                <strong>Title:</strong>
              </label>{" "}
              {currentData.titleRu}
            </div>
          </div>
        ) : data ? (
          <div>
            <br />
            <p>Please click on a Card...</p>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Service;
