export const initialValues = {
  _id: null,
  titleRu: "",
  titleUzk: "",
  titleUz: "",
  contentRu: "",
  contentUzk: "",
  contentUz: "",
  coverImage: "",
  slug: "",
  for: "",
};
